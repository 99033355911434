<template lang="pug">
  .book
    header.header(
      :class="{'header--menu-opened': menuOpened}"
    )
      .header__logo(
        :class="{'header__logo--reduced': headerLogoReduced}"
      )
      .header__burger(
        @click="menuOpened = !menuOpened"
      )
        .header__burger__line
        .header__burger__line
    main.main
      Menu(
        v-show="menuOpened"
        :content="menuContent"
        :fixed="menuFixed"
        @moveToChapter="moveToChapter"
      )
      Chapter(
        v-for="(chapter, index) in chapters"
        :key="index"
        :id="chapter.id"
        :content="chapter.data"
      )
      .progress-circle-wrapper
        ProgressCircle(
          :progress-percentage="progressPercentage"
        )
      transition(name='cookies-hide-animation')
        Cookies(
          v-if="!cookiesAccepted"
          @acceptCookies="acceptCookies"
        )
    footer.footer
      img.footer__logo(src='@/assets/logobrand.png')
      .footer__info
        .footer__info__address
          p Borgartún 35
          p 105 Reykjavík
        .footer__info__contact-details
          p +354 5884430
          p samorka@samorka.is
</template>

<script>
import Menu from './Menu.vue';
import Chapter from './Chapter.vue';
import ProgressCircle from './ProgressCircle.vue';
import Cookies from './Cookies.vue';

export default {
  name: 'Book',
  components: {
    Menu,
    Chapter,
    ProgressCircle,
    Cookies,
  },
  data() {
    return {
      chapters: [],
      menuContent: [],
      headerLogoReduced: false,
      menuOpened: true,
      menuFixed: false,
      progressPercentage: 0,
      cookiesAccepted: true,
    };
  },
  created() {
    this.getData();
    if (window.innerWidth < 768) {
      this.menuOpened = false;
    }
    if (localStorage.getItem('cookies-accepted') !== 'true') {
      this.cookiesAccepted = false;
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      if (window.innerWidth < 768) {
        this.menuOpened = false;
      } else {
        this.menuOpened = true;
        if (document.body.hasAttribute('unscrollable')) {
          this.makeBodyScrollable();
        }
      }
    });
    window.addEventListener('scroll', () => {
      if (window.scrollY > 0) {
        this.headerLogoReduced = true;
      } else {
        this.headerLogoReduced = false;
      }
      const mainOffsetTop = this.$el.querySelector('.main').offsetTop;
      if (window.scrollY > mainOffsetTop) {
        this.menuFixed = true;
      } else {
        this.menuFixed = false;
      }
      this.$el.querySelectorAll('.chapter').forEach((chapter) => {
        const chapterPositionData = chapter.getBoundingClientRect();
        const targetMenuItemIndex = this.menuContent
          .findIndex((menuItem) => menuItem.id === chapter.getAttribute('data-id'));
        if (chapterPositionData.top <= 0 && chapterPositionData.bottom > 0) {
          this.menuContent[targetMenuItemIndex].isActive = true;
          const progressPercentage = (
            Math.abs(chapterPositionData.top) / (chapterPositionData.height - window.innerHeight)
          ) * 100;
          this.menuContent[targetMenuItemIndex].progressPercentage = progressPercentage;
          this.progressPercentage = progressPercentage;
        } else {
          this.menuContent[targetMenuItemIndex].isActive = false;
        }
      });
    });
  },
  methods: {
    async getData() {
      const book = await this.$prismic.client.getSingle('book');
      const chaptersIDs = book.data.chapter
        .map((chapterItem) => {
          if (chapterItem.chapter_link.id && !chapterItem.chapter_link.isBroken) {
            return chapterItem.chapter_link.id;
          }
          return '';
        })
        .filter((chaptersID) => chaptersID.length);
      const chaptersData = await this.$prismic.client.getByIDs(chaptersIDs);
      this.chapters = chaptersData.results;
      this.menuContent = chaptersData.results.map((result, index) => {
        const menuItemData = {
          title: result.data.title[0].text,
          isActive: false,
          progressPercentage: 0,
          id: result.id,
        };
        if (index + 1 > 9) {
          menuItemData.index = `${index + 1}.`;
        } else {
          menuItemData.index = `0${index + 1}.`;
        }
        return menuItemData;
      });
    },
    moveToChapter(chapterID) {
      window.scrollTo({
        top: this.$el.querySelector(`.chapter[data-id="${chapterID}"]`).offsetTop + 10,
        behavior: 'smooth',
      });
      if (window.innerWidth < 768) {
        this.menuOpened = false;
      }
    },
    makeBodyScrollable() {
      document.body.removeAttribute('unscrollable');
    },
    makeBodyUnscrollable() {
      document.body.setAttribute('unscrollable', '');
    },
    acceptCookies() {
      localStorage.setItem('cookies-accepted', 'true');
      this.cookiesAccepted = true;
    },
  },
  watch: {
    menuOpened(newMenuOpenedValue) {
      if (window.innerWidth > 767) {
        return;
      }
      if (newMenuOpenedValue) {
        this.makeBodyUnscrollable();
      } else {
        this.makeBodyScrollable();
      }
    },
  },
};
</script>

<style lang="scss">
.book {
  padding-top: 100px;
  font-family: $font-base;
  line-height: 1.5;
  color: $color-daintree;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @include mq($until: md) {
    padding-top: 0;
  }
}
.progress-circle-wrapper {
  position: fixed;
  right: 30px;
  bottom: 30px;
  display: none;
  z-index: 99;
  font-size: 16px;
  @include mq($until: md) {
    display: block;
  }
  .progress-circle {
    position: static;
  }
}
.header {
  position: fixed;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq($until: md) {
    right: 0;
    left: 0;
    padding-right: 10px;
    padding-left: 30px;
    padding-top: 28px;
  }
  &__logo {
    width: 184px;
    height: 50px;
    background-image: url('~@/assets/logoname.png');
    background-position: 0% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    transition: all .2s ease;
    @include mq($until: md) {
      width: 130px;
      height: 35px;
    }
    &--reduced {
      width: 50px;
      background-image: url('~@/assets/logobrand.png');
      @include mq($from: md) {
        transform: translateY(-50px);
      }
      @include mq($until: md) {
        width: 35px;
      }
    }
  }
  &__burger {
    width: 60px;
    height: 60px;
    padding: 20px;
    margin-left: auto;
    display: none;
    flex-direction: column;
    justify-content: center;
    transition: all .1s ease-in-out;
    @include mq($until: md) {
      display: flex;
    }
    &__line {
      height: 2px;
      border-radius: 2px;
      background: $gradient-bluish-green;
      transition: all .2s ease;
      &:first-child {
        transform: translateY(-2px);
      }
      &:last-child {
        transform: translateY(2px);
      }
    }
  }
  &--menu-opened {
    .header__burger {
      padding: 16px;
      &__line {
        &:first-child {
          transform: translateY(1px) rotate(-45deg);
        }
        &:last-child {
          transform: translateY(-1px) rotate(45deg);
        }
      }
    }
    @include mq($until: md) {
      .header__logo {
        width: 35px;
        background-image: url('~@/assets/logobrand.png');
      }
    }
  }
}
.main {
  margin-top: 100px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  @include mq($until: md) {
    margin-top: 0;
    padding-top: 80px;
    padding-bottom: 24px;
  }
}
.footer {
  padding-top: 28px;
  padding-bottom: 50px;
  margin-top: auto;
  border-top: 1px solid $color-bondi-blue;
  display: flex;
  align-items: center;
  &__logo {
    width: 57px;
    height: 57px;
    @include mq($until: md) {
      width: 45px;
      height: 45px;
    }
  }
  &__info {
    font-size: 14px;
    font-weight: bold;
    flex-grow: 1;
    display: flex;
    &__address {
      padding-left: 11%;
    }
    &__contact-details {
      padding-left: 9%;
    }
  }
}
</style>
