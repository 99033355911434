<template lang="pug">
  .cookies-wrapper
    .container
      .cookies
        .cookies__message
          p.cookies__message__title Við notum vafrakökur
          p.cookies__message__text.
            til þess að viðhalda og bæta vefinn meðal annars til að sjá hvaða efni
            notendurnir sækjast eftir og til að mæla umferð á vefinn.
        .cookies__action
          button.cookies__action__accept-button(
            @click="acceptCookies"
          ) Loka
</template>

<script>
export default {
  name: 'Cookies',
  methods: {
    acceptCookies() {
      this.$emit('acceptCookies');
    },
  },
};
</script>

<style lang="scss">
.cookies {
  padding-top: 17px;
  padding-bottom: 22px;
  display: flex;
  @include mq($until: md) {
    align-items: center;
  }
  @include mq($until: sm) {
    flex-direction: column;
    align-items: flex-start;
  }
  &-wrapper {
    position: fixed;
    z-index: 99;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: white;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  }
  &__message {
    flex-grow: 1;
    color: $color-daintree;
    padding-right: 20px;
    font-size: 16px;
    @include mq($until: sm) {
      padding-right: 0;
    }
    &__title {
      font-weight: bold;
      @include mq($until: sm) {
        margin-bottom: 7px;
      }
    }
    &__text {
      @include mq($until: sm) {
        margin-bottom: 16px;
      }
    }
  }
  &__action {
    &__accept-button {
      padding-top: 12px;
      padding-right: 70px;
      padding-bottom: 12px;
      padding-left: 70px;
      border-radius: 20px;
      background: $gradient-bluish-green;
      color: white;
      font-size: 16px;
      line-height: 1.5;
      font-weight: bold;
    }
  }
}
</style>
